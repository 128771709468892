<template>
  <v-content>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="700"
    >
      <v-card>
        <v-card-title>Busqueda de empleado</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <h2>Impresión de Credencial</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="8">
              <v-text-field
                v-model="dni"
                outlined
                type="number"
                :rules="[Validaciones.requerido]"
                label="DNI"
                placeholder="Ingrese su Usuario"
                class="mb-3"
                hide-details=""
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-btn
                color="primary"
                :loading="loadingButton"
                @click="searchEmployee"
              >
                <v-icon>
                  {{ icons.mdiAccountSearchOutline }}
                </v-icon> Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-tooltip
            min-width="400px"
            z-index="250"
          >
            <span></span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            flat
            @click="dialog = false;"
          >
            Cancel
          </v-btn>
          </v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>
<script>

import axios from 'axios'
import { mdiPrinter, mdiAccountSearchOutline } from '@mdi/js'

export default {
  icons: {
    mdiAccountSearchOutline,
    mdiPrinter,

  },

  data() {
    return {
      dialog: false,
      dni: null,
      loadingButton: false,
      icons: {
        mdiPrinter,
        mdiAccountSearchOutline,
      },
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
      },
    }
  },
  mounted() {
    location.reload
    this.$forceUpdate()

    this.dialog = true
  },
  methods: {

    async searchEmployee() {
      this.url = `${this.$store.getters.urlBase}/api/Employee/employeeByDNI`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { dni: parseInt(this.dni) },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)

      if (respuesta.status === 200) {
        this.$router.push({ name: 'reportcredential', params: { dni: `${respuesta.data.dni}`, rol: respuesta.data.rol, employee: `${respuesta.data.surname} ${respuesta.data.name}` } })
      } else {

        // this.colorSnack = "red";
        // this.mensajeSnack = respuesta.data;
        // this.Showsnackbar = true;
      }
    },
  },
}
</script>
